import i18n from '@/i18n';
import axios from 'axios';

const makeUrl = (path) => {
  return `${process.env.VUE_APP_API_URL}${path}`;
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  namespaced: true,
  state() {
    return {
      lastRequest: Date.now()
    }
  },
  mutations: {
    setLastRequest(state) {
      state.lastRequest = Date.now();
    }
  },
  actions: {
    async login(ctx, data) {

      // check if lastRequest is less than VUE_APP_API_REQUEST_DELAY ago
      if(Date.now() - this.state.api.lastRequest < process.env.VUE_APP_API_REQUEST_DELAY) return;

      // set last request
      this.commit('api/setLastRequest');

      const { email, password } = data;

      await sleep(500);

      const url = makeUrl('/user/login');
      
      const response = await axios.put(url, {
        email,
        password
      }).catch(err => {
        return {
          ...err.response
        }
      });

      if(response.status === 401) {
        this.dispatch('toast/warning', { message: i18n.global.t('landing.signup.invalidCredentials') });
      }
      else if(response.status === 201) {
        this.dispatch('toast/success', { message: i18n.global.t('landing.signup.loginSuccess') });
      }
      else {
        this.dispatch('toast/warning', { message: i18n.global.t('landing.signup.tooManyRequests') });
      }

      return response;
    },
    async register(ctx, data) {

      // check if lastRequest is less than VUE_APP_API_REQUEST_DELAY ago
      if(Date.now() - this.state.api.lastRequest < process.env.VUE_APP_API_REQUEST_DELAY) return;

      // set last request
      this.commit('api/setLastRequest');

      const { email, password, retypePassword } = data;

      // check if passwords match
      if(password !== retypePassword) {
        this.dispatch('toast/warning', { message: i18n.global.t('landing.signup.passwordsDontMatch') });
        return;
      }

      await sleep(500);

      const url = makeUrl('/user/create');
      const response = await axios.post(url, {
        email,
        password
      }).catch(err => {
        return {
          ...err.response
        }
      });

      if(response.status === 401) {
        this.dispatch('toast/warning', { message: '401 register' });
      }
      else if(response.status === 200) {
        this.dispatch('toast/success', { message: '200 register' });
        // this.dispatch('user/setUser', response.data);
      }
      else {
        this.dispatch('toast/warning', { message: `register api.js: ${response.status}` });
      }
    },
    async user(ctx, data) {
      const url = makeUrl('/user');
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      }).catch(err => {
        return false;
      });

      if(response.status !== 200) {
        await this.dispatch('user/remove');
        return false;
      };

      await this.dispatch('user/set', { ...response.data, token: data.token });
      return true;
    },
    async folder(ctx, data) {
      const url = makeUrl('/folder');
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      }).catch(err => {
        return false;
      });

      return response;
    }
  }
}
<template>
  <div
    id="forgotPassword-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative w-full max-w-md h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="closeModal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg>
          <span class="sr-only">{{ $t('landing.register.close') }}</span>
        </button>
        <div class="py-6 px-6 lg:px-8">
          <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            {{ $t('landing.forgotPassword.title') }}
          </h3>
          <form
            class="space-y-6"
            action="#"
          >
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >{{ $t('landing.register.email') }}</label>
              <input
                id="forgotPasswordEmail"
                type="email"
                autocomplete="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="mail@example.com"
                required
              >
            </div>
            <div>
              <button
                type="submit"
                class="mb-4 w-full text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-500 dark:hover:bg-primary-600 dark:focus:ring-primary-400"
              >
                {{ $t('landing.forgotPassword.sendLink') }}
              </button>
              <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
                {{ $t('landing.forgotPassword.back') }} <a
                  class="text-primary-500 hover:underline dark:text-primary-400 cursor-pointer"
                  @click="openLoginModal"
                >{{ $t('landing.register.login') }}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: {'openLogin': null},
    data() {
      return {
        modal: null
      }
    },
    mounted() {
      this.modal = new Modal(document.querySelector('#forgotPassword-modal'));
    },
    methods: {
      showModal() {
        this.modal.show();
        document.querySelector('#email').focus();
      },
      closeModal() {
        this.modal.hide();
      },
      openLoginModal() {
        this.closeModal();
        this.$emit('openLogin');
      }
    }
  }
</script>

<template>
  <!-- Modals -->
  <SignUp
    ref="signUp"
    @open-register="showRegisterModal"
    @open-forgot-password="showForgotPasswordModal"
  />
  <Forgot-Password
    ref="signUpForgotPassword"
    @open-login="showSignUpModal"
  />
  <Register
    ref="signUpRegister"
    @open-login="showSignUpModal"
  />

  <!-- Content -->
  <Navbar />
  <Hero @open-login="showSignUpModal" />
  <Features />
  <Testimonials />
  <Pricing />
  <Footer />
</template>


<script>
import SignUp from './SignUp/index.vue';
import ForgotPassword from './SignUp/forgotPassword.vue';
import Register from './SignUp/register.vue';
import Navbar from './Navbar/index.vue';
import Hero from './Hero/index.vue';
import Footer from './Footer/index.vue';

export default {
  components: {
    SignUp,
    ForgotPassword,
    Register,
    Navbar,
    Hero,
    Footer,
  },
  data() {
    return {
      session: false,
    };
  },
  created() {
    window.addEventListener('keydown', (e) => {
      // check if user is not typing in an input
      if (document.activeElement.tagName === 'INPUT') return;

      switch(e.keyCode) {
        // Close all modals 'ESC'
        case 27:
          e.preventDefault();
          this.$refs.signUp.closeModal();
          this.$refs.signUpForgotPassword.closeModal();
          this.$refs.signUpRegister.closeModal();
          break;
        // Toggle dark mode 'd'
        case 68:
          e.preventDefault();
          this.$store.dispatch('toggleDarkMode');
          break;
        // Open login modal 'l'
        case 76:
          e.preventDefault();
          this.$refs.signUpForgotPassword.closeModal();
          this.$refs.signUpRegister.closeModal();
          this.$refs.signUp.showModal();
          break;
        // Open register modal 'r'
        case 82:
          e.preventDefault();
          this.$refs.signUp.closeModal();
          this.$refs.signUpForgotPassword.closeModal();
          this.$refs.signUpRegister.showModal();
          break;
        // Open forgot password modal 'f'
        case 70:
          e.preventDefault();
          this.$refs.signUp.closeModal();
          this.$refs.signUpRegister.closeModal();
          this.$refs.signUpForgotPassword.showModal();
          break;
      }
    });
  },
  async mounted() {
    // Check if user is logged in
    // this.session = !!(await this.$store.dispatch('user/get')).token;
  },
  methods: {
    showSignUpModal() {
      this.$refs.signUp.showModal();
    },
    showForgotPasswordModal() {
      this.$refs.signUpForgotPassword.showModal();
    },
    showRegisterModal() {
      this.$refs.signUpRegister.showModal();
    }
  }
}
</script>

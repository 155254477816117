<template>
  <div class="h-screen w-100 flex dashboard">
    <Sidebar />

    <div>
      <Navbar />
    </div>
  </div>
</template>

<script>
import Navbar from './Navbar/index.vue'
import Sidebar from './Sidebar/index.vue'


export default {
  components: {
    Navbar,
    Sidebar
  },
  async mounted() {
    // Check if user is logged in
    const check = await this.$store.dispatch('user/check');
    if(!check) this.$router.push({ name: 'landing' });

    this.$store.dispatch('user/folder')
      .then(a => console.debug(a.data));
  }
}
</script>
import i18n from '@/i18n';
import store2 from 'store2';

export default {
  namespaced: true,
  state() {
    return {
    }
  },
  mutations: {
  },
  actions: {
    set(ctx, data) {
      store2({
        billingStatus: data.billingStatus,
        email: data.email,
        userId: data.userId,
        created: data.created,
        gigabytes: data.gigabytes,
        sessionExpires: data.sessionExpires,
        token: data.token,
        verified: data.verified
      });
    },
    get(ctx) {
      const data = store2();
      return {
        billingStatus: data.billingStatus,
        email: data.email,
        userId: data.userId,
        created: data.created,
        gigabytes: data.gigabytes,
        sessionExpires: data.sessionExpires,
        token: data.token,
        verified: data.verified
      };
    },
    remove(ctx) {
      store2.remove('billingStatus');
      store2.remove('email');
      store2.remove('userId');
      store2.remove('created');
      store2.remove('gigabytes');
      store2.remove('sessionExpires');
      store2.remove('token');
      store2.remove('verified');
    },
    async check(ctx) {
      // Get token from store
      const token = store2('token');
      
      // If token is not set, return false
      if (!token) return false;

      // Get session expiration from store
      const sessionExpires = store2('sessionExpires');

      // If session expiration is not set, return false
      if (!sessionExpires) {
        // Make request to api/user
        const request = await this.dispatch('api/user', { token });

        return request;
      };

      // If session expiration is in the past, return false
      if (sessionExpires < Date.now() / 1000) {
        // Make request to api/user
        const request = await this.dispatch('api/user', { token });

        return request;
      };

      // Make request to api/user
      const request = await this.dispatch('api/user', { token });

      return request;
    },
    async folder(ctx) {
      // Get token from store
      const token = store2('token');
      
      // If token is not set, return false
      if (!token) return false; 

      // Make request to api/user
      const request = await this.dispatch('api/folder', { token });

      store2.set('folder_', JSON.stringify(request))

      return request;
    }
  }
}
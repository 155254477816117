import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing/index.vue'
import Dashboard from '../views/Dashboard/index.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing
  },
  {
    path: '/d',
    name: 'dashboard',
    component: Dashboard
  },

  // Redirect if no site found
  {
    path: '/:redirect',
    name: 'landing',
    component: Landing
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div
    class="bg-gray-50 dark:bg-gray-900"
  >
    <router-view />

    <div
      id="toast-container"
      class="fixed bottom-0 right-0 p-8 z-50 overflow-hidden"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$i18n.locale = localStorage.getItem('locale') || 'en';
    
    if(!localStorage.getItem('darkMode') || localStorage.getItem('darkMode') === 'true') {
    document.querySelector('#app').classList.add('dark');
    }
  }
}
</script>
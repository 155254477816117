const warning = (message) => `<div class="translate-x-96 opacity-0 transition-all duration-500 mt-4 flex items-center p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
  <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
      <span class="sr-only">Warning icon</span>
  </div>
  <div class="ml-3 text-sm font-normal">${message}</div>
</div>`;

const success = (message) => `<div class="translate-x-96 opacity-0 transition-all duration-500 mt-4 flex items-center p-4 mb-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
  <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
  <span class="sr-only">Check icon</span>
      <span class="sr-only">Success icon</span>
  </div>
  <div class="ml-3 text-sm font-normal">${message}</div>
</div>`;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


export default {
  namespaced: true,
  state() {
    return {
      list: [],
    }
  },
  mutations: {
    addToast(state, toast) {
      state.list.push(toast);
    }
  },
  actions: {
    async warning(ctx, data) {
      const { message } = data;

      // create toast in DOM
      const toast = document.createElement('div');
      toast.innerHTML = warning(message);

      // append toast to DOM
      document.querySelector('#toast-container').appendChild(toast);
      
      await sleep(1);
      toast.querySelector('div').classList.remove('translate-x-96');
      toast.querySelector('div').classList.remove('opacity-0');

      // push toast to right after 2.5 seconds
      await sleep(2500);
      toast.querySelector('div').classList.add('translate-x-96');
      toast.querySelector('div').classList.add('opacity-0');

      // delete after a second
      await sleep(1000);
      toast.remove();
    },
    async success(ctx, data) {
      const { message } = data;

      // create toast in DOM
      const toast = document.createElement('div');
      toast.innerHTML = success(message);

      // append toast to DOM
      document.querySelector('#toast-container').appendChild(toast);
      
      await sleep(1);
      toast.querySelector('div').classList.remove('translate-x-96');
      toast.querySelector('div').classList.remove('opacity-0');

      // push toast to right after 2.5 seconds
      await sleep(2500);
      toast.querySelector('div').classList.add('translate-x-96');
      toast.querySelector('div').classList.add('opacity-0');

      // delete after a second
      await sleep(1000);
      toast.remove();
    },
  }
}
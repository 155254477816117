import { createStore } from "vuex";

// import utils from './modules/api'
import api from './modules/api';
import toast from './modules/toast';
import user from './modules/user';

export default createStore({
  modules: {
    api,
    toast,
    user,
  },
  state() {
    return {
      darkMode: false,
    };
  },
  actions: {
    toggleDarkMode() {
      const currentDarkMode = localStorage.getItem("darkMode");
      const newDarkMode = currentDarkMode === "true" ? false : true;
      this.state.darkMode = newDarkMode;

      localStorage.setItem('darkMode', newDarkMode);
  
      if(newDarkMode) {
        document.querySelector('#app').classList.add('dark');
      } else {
        document.querySelector('#app').classList.remove('dark');
      }

      // if #theme-icon exists
      if(document.querySelector('#theme-icon')) {
        if(newDarkMode) {
          document.querySelector('#theme-icon').classList.remove('fa-sun');
          document.querySelector('#theme-icon').classList.add('fa-moon');
        } else {
          document.querySelector('#theme-icon').classList.add('fa-sun');
          document.querySelector('#theme-icon').classList.remove('fa-moon');
        }
      }
    }
  }
});
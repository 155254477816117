<template>
  <section class="bg-gray-50 dark:bg-gray-900 my-20 sm:px-10">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
      <div class="mr-auto place-self-center lg:col-span-7">
        <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
          {{ $t('landing.hero.title.whiteOne') }} <span class="text-primary-500">{{ $t('landing.hero.title.primaryOne') }}</span> {{ $t('landing.hero.title.whiteTwo') }} <span class="text-primary-500">{{ $t('landing.hero.title.primaryTwo') }}</span> {{ $t('landing.hero.title.whiteThree') }} <span class="text-primary-500">{{ $t('landing.hero.title.primaryThree') }}</span>
        </h1>
        <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          {{ $t('landing.hero.description') }}
        </p>
        <button
          type="button"
          class="py-3 mr-4 px-5 text-base font-medium text-center text-white bg-primary-500 rounded-lg hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-600 dark:focus:ring-primary-600 inline-flex items-center"
          @click="$emit('open-login')"
        >
          {{ $t('landing.hero.signUp') }}
          <svg
            aria-hidden="true"
            class="ml-2 -mr-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg>
        </button>
        <a
          href="#"
          class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
        >
          {{ $t('landing.hero.getStarted') }}
        </a> 
      </div>
      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
        <img
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png"
          alt="mockup"
        >
      </div>
    </div>
  </section>
</template>
